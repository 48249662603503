import React, { useCallback, useEffect, useState } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import Cookies from 'js-cookie'
import { useSelector, useStore } from 'react-redux'
import { A } from '~elements'
import { fetchPageData } from '~store/page/actions'
import { selectRibbonData } from '~store/page/selectors'
import isArrayWithLength from '~utils/isArrayWithLength'
import isObjectWithProperties from '~utils/isObjectWithProperties'
import parseCustomStyleProps from '~utils/parseCustomStyleProps'
import { toKebabCase } from '~utils/string'
import styles from './Ribbon.module.scss'

const HIDE_COOKIE_VALUE = 'hide'
const SHOW_COOKIE_VALUE = 'show'

export const loadData = ({ store }) => {
  return [store.dispatch(fetchPageData())]
}
const styleMap = {
  backgroundColor: { property: 'background-color' },
  closeButtonColor: { property: 'text-color-close-button' },
  headlineColor: { property: 'text-color-headline' },
  labelBackgroundColor: { property: 'background-color-label' },
  labelTextColor: { property: 'text-color-label' },
  textColor: { property: 'text-color' }
}

const UNICODE_CROSS = String.fromCharCode(10005)

const Ribbon = ({ className }) => {
  const ribbonData = useSelector(selectRibbonData)
  const hasRibbonData = isObjectWithProperties(ribbonData)
  const [isShowingRibbon, setIsShowingRibbon] = useState(false)
  const store = useStore()

  const ribbonRef = useCallback(
    domElement => {
      if (domElement) {
        parseCustomStyleProps(domElement, ribbonData, styleMap)
      }
    },
    [hasRibbonData]
  )

  useEffect(() => {
    if (!hasRibbonData) {
      loadData({ store })
    }
  }, [hasRibbonData])

  useEffect(() => {
    if (!ribbonData?.ribbonId) return

    const ribbonCookie = Cookies.get(ribbonData.ribbonId)
    const shouldShowRibbon = ribbonCookie === SHOW_COOKIE_VALUE

    if (ribbonCookie) {
      setIsShowingRibbon(shouldShowRibbon)
    } else {
      Cookies.set(ribbonData.ribbonId, SHOW_COOKIE_VALUE)
      setIsShowingRibbon(true)
    }
  }, [ribbonData?.ribbonId])

  if (!isShowingRibbon || !hasRibbonData) return null

  const { component, headline, image, labelText, link, supportingText } =
    ribbonData
  const { target, text: linkName, url } = link[0]
  const trimmedUrl = typeof url === 'string' ? url.trim() : url
  const isLargeRibbon = component === 'module_large_ribbon'
  const classNameLink = isArrayWithLength(link) ? 'has-link' : null
  const classNameLargeRibbon = isLargeRibbon ? 'large-ribbon' : null
  const dataLinkModule = isLargeRibbon ? 'promo-large' : 'promo-small'
  const classNames = cx(
    styles.root,
    className,
    classNameLink,
    classNameLargeRibbon
  )

  const hideRibbon = event => {
    Cookies.set(ribbonData.ribbonId, HIDE_COOKIE_VALUE)
    setIsShowingRibbon(false)
  }

  const handleClick = event => {
    const { altKey, ctrlKey, currentTarget, metaKey, shiftKey, target } = event
    const validTargets = ['button', 'a']
    const nodeName = target.nodeName.toLowerCase()

    if (!validTargets.includes(nodeName)) {
      const link = currentTarget.querySelector('a[data-linktype=cta]')

      if (link) {
        link.dataset.linkname = target.dataset.linkname || 'ribbon'

        const clickEventOptions = {
          altKey,
          ctrlKey,
          metaKey,
          shiftKey
        }

        link.dispatchEvent(new MouseEvent('click', clickEventOptions))
      }
    }
  }

  const RibbonImage = image && (
    <img
      alt=""
      className={styles['ribbon-image']}
      data-linkname="img"
      src={image}
    />
  )

  const RibbonLabel = labelText && (
    <aside className={styles.label}>{labelText}</aside>
  )

  const RibbonLink = trimmedUrl && (
    <A
      data-analytics="aa-track"
      data-linklocation="header"
      data-linkmodule={dataLinkModule}
      data-linkname={toKebabCase(linkName)}
      data-linktype="cta"
      data-linkurl={trimmedUrl}
      href={trimmedUrl}
      target={target}
    >
      {linkName}
    </A>
  )

  const RibbonSupport = supportingText && (
    <>
      <span>{supportingText}</span>{' '}
    </>
  )

  return (
    <div
      aria-hidden
      className={classNames}
      data-linkname="ribbon"
      data-type="ribbon"
      onClick={handleClick}
      ref={ribbonRef}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {RibbonLabel}
          <p className={styles.head}>{headline}</p>
          <p className={styles.support}>
            {RibbonSupport}
            {RibbonLink}
          </p>
        </div>
        {RibbonImage}
      </div>
      <button className={styles.close} onClick={hideRibbon}>
        {UNICODE_CROSS}
      </button>
    </div>
  )
}

Ribbon.propTypes = {
  className: string
}

Ribbon.defaultProps = {
  className: null
}

export default withStyles(styles)(Ribbon)

import { configureStore } from '@reduxjs/toolkit'
import accountsReducer from './accounts/slice'
import carambolaAdReducer from './carambolaAd/slice'
import cmasReducer from './cmas/slice'
import learningHubReducer from './learningHub/slice'
import lexigraphSlice from './lexigraph/slice'
import localDataReducer from './localData/slice'
import locationReducer from './location/slice'
import lunaReducer from './luna/slice'
import pageReducer from './page/slice'
import tunaReducer from './tuna/slice'
import userContextReducer from './userContext/slice'
import wordpressReducer from './wordpress/slice'

export const reducer = {
  accounts: accountsReducer,
  carambolaAd: carambolaAdReducer,
  cmas: cmasReducer,
  learningHub: learningHubReducer,
  lexigraph: lexigraphSlice,
  localData: localDataReducer,
  location: locationReducer,
  luna: lunaReducer,
  page: pageReducer,
  tuna: tunaReducer,
  userContext: userContextReducer,
  wordpress: wordpressReducer
}

export const createStore = preloadedState => {
  const store = configureStore({
    preloadedState,
    reducer
  })

  return store
}

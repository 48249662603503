import React from 'react'
import { func, object, string } from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { PROPTYPE_CHILDREN } from '~client/constants'

const A = ({ children, clickLocation, href, linkId, onClick, ...props }) => {
  const navigate = useNavigate()
  const uriIsHash = href?.[0] === '#' && href !== '#'
  const uriStartsWithHttps = /^https?/.test(href)
  const useLink = !uriStartsWithHttps && !uriIsHash

  const clickLocationStr =
    clickLocation &&
    Object.entries(clickLocation)
      .map(([key, value]) => `${key}=${value}`)
      .join('|')

  const handleClick = event => {
    if (linkId) {
      event.preventDefault()
      if (useLink) navigate(href)
      else window.location.href = href
    }

    if (onClick) onClick(event)
  }

  if (useLink) {
    return (
      <Link
        data-linkid={linkId}
        data-location={clickLocationStr}
        onClick={handleClick}
        to={href}
        {...props}
      >
        {children}
      </Link>
    )
  } else if (uriIsHash) {
    return (
      <HashLink smooth to={href} {...props}>
        {children}
      </HashLink>
    )
  } else {
    return (
      <a
        data-linkid={linkId}
        data-location={clickLocationStr}
        href={href}
        onClick={handleClick}
        {...props}
      >
        {children}
      </a>
    )
  }
}

A.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  clickLocation: object,
  href: string,
  linkId: string,
  onClick: func
}

A.defaultProps = {
  clickLocation: null,
  href: null,
  linkId: null,
  onClick: null
}

export default A

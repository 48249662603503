import React from 'react'
import { func, object, string } from 'prop-types'
import { Button as RkButton } from 'ariakit'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './Button.module.scss'

const Button = ({
  children,
  className,
  linkId,
  clickLocation,
  onClick,
  ...props
}) => {
  const classNames = cx(styles.button, className)

  const clickLocationStr =
    clickLocation &&
    Object.entries(clickLocation)
      .map(([key, value]) => `${key}=${value}`)
      .join('|')

  const handleClick = event => {
    if (onClick) onClick(event)
  }

  return (
    <RkButton
      className={classNames}
      data-linkid={linkId}
      data-location={clickLocationStr}
      data-type="button"
      onClick={handleClick}
      {...props}
    >
      {children}
    </RkButton>
  )
}

Button.propTypes = {
  children: PROPTYPE_CHILDREN,
  className: string,
  clickLocation: object,
  linkId: string,
  onClick: func
}

Button.defaultProps = {
  className: null,
  clickLocation: null,
  linkId: null,
  onClick: null
}

export default withStyles(styles)(Button)

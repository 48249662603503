import { createSlice } from '@reduxjs/toolkit'

export const cmasSlice = createSlice({
  initialState: {
    useCmas: false
  },
  name: 'cmas',
  reducers: {
    setCmas: (state, { payload }) => {
      state.useCmas = payload
    }
  }
})

export const actions = cmasSlice.actions

export const selectCmas = state => state.cmas?.useCmas

export default cmasSlice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'
import gql from 'graphql-tag'
import getCachedQueryResults from '~utils/getCachedQueryResults'

const endpoint = process.env.SAFE_LEXIGRAPH_ENDPOINT
const BROWSE_RESULTS_PER_PAGE = 300;

const GET_DICTIONARY_RESULTS = gql`
  fragment DictionaryResultsCollinsEntry on CollinsEntry {
    __typename
    headword {
      displayForm
      slug
    }
    homonym
    note {
      noteChunks
    }
    origin {
      originChunks
    }
    partOfSpeechGroups {
      definitions {
        definitionSetChunks: definitionChunks
        examples {
          text
        }
        idiom
        ordinal
        sense
        subdefinitions {
          definitionSetChunks: definitionChunks
          examples {
            text
          }
          ordinal
        }
      }
      ordinal
      partOfSpeech
    }
    pronunciations {
      ipa
    }
    subEntry {
      headword {
        displayForm
      }
      partsOfSpeech
    }
  }

  fragment DictionaryResultsCulturalEntry on CulturalEntry {
    __typename
    definitions {
      definitionSetChunks: definitionChunks
    }
    headword {
      displayForm
      slug
    }
    notes {
      noteChunks
    }
  }

  fragment DictionaryResultsEntry on Entry {
    __typename
    audioPronunciations {
      mpeg
    }
    confusableNotes {
      noteChunks
    }
    genderNotes {
      noteChunks
    }
    grammarNotes {
      noteChunks
    }
    headword {
      displayForm
      slug
    }
    idioms {
      definitions {
        definitionSetChunks
        examples
        ordinal
        subdefinitions {
          definitionSetChunks
          examples
          ordinal
        }
      }
      phraseChunks
    }
    orderKey
    ordinal
    origin {
      originChunks
    }
    partOfSpeechBlock(options: $partOfSpeechBlockOptions) {
      definitions(options: $definitionOptions) {
        antonymsConnection {
          edges {
            displayForm
            slug
          }
        }
        definitionSetChunks
        examples
        ordinal
        subdefinitions {
          definitionSetChunks
          examples
          ordinal
        }
        synonymsConnection {
          edges {
            displayForm
            slug
          }
        }
      }
      inflectionSets {
        inflectionChunks
      }
      labelSet {
        labelSetText
      }
      ordinal
      partOfSpeech
    }
    pronunciationNotes {
      noteChunks
    }
    pronunciationSet {
      pronunciationSetChunk
    }
    relatedFormSets {
      chunks
      ordinal
    }
    sensitiveNotes {
      noteChunks
    }
    spellingNotes {
      noteChunks
    }
    synonymStudy {
      noteChunks
    }
    usageAlerts {
      noteChunks
    }
    usageNotes {
      noteChunks
    }
    variants {
      variantChunk
    }
    verbPhrases {
      definitions {
        definitionSetChunks
        examples
        ordinal
        subdefinitions {
          definitionSetChunks
          examples
          ordinal
        }
      }
    }
  }

  fragment DictionaryResultsIdiomsEntry on IdiomsEntry {
    __typename
    descriptionChunks
    definitions: subEntries {
      descriptionChunks
    }
    headword {
      displayForm
      slug
    }
  }

  fragment DictionaryResultsScienceEntry on ScienceEntry {
    __typename
    headword {
      displayForm
      slug
    }
    note {
      noteChunks
      title
    }
    partOfSpeechGroups {
      definitions {
        definitionSetChunks: definitionChunks
        label
        ordinal
        subdefinitions {
          definitionSetChunks: definitionChunks
          ordinal
        }
      }
      ordinal
      inflections {
        inflection
        label
        pronunciation {
          text
        }
      }
      partOfSpeech
    }
    pronunciation {
      text
    }
  }

  fragment DictionaryResultsThesaurusEntry on ThesaurusEntry {
    __typename
    headword {
      displayForm
      slug
    }
    id
    partOfSpeechGroups {
      shortDefinitions {
        synonyms {
          similarity
          targetWord
        }
        synonymsConnection {
          edges {
            node {
              targetWord
            }
            similarity
            targetSlug
          }
        }
        thesRid
      }
    }
  }

  query DictionaryResults(
    $definitionOptions: DefinitionOptions
    $partOfSpeechBlockOptions: PartOfSpeechBlockOptions
    $where: SlugWhere
  ) {
    slugs(where: $where) {
      entries {
        ...DictionaryResultsCollinsEntry
        ...DictionaryResultsCulturalEntry
        ...DictionaryResultsEntry
        ...DictionaryResultsIdiomsEntry
        ...DictionaryResultsScienceEntry
        ...DictionaryResultsThesaurusEntry
      }
      url
    }
  }
`

const GET_THESAURUS_COMPARE_SYNONYMS = gql`
  query ThesaurusCompareSynonyms(
    $definitionOrdinal: Int!
    $matchOn: [String!]!
    $partOfSpeech: String!
    $root: String!
  ) {
    compareSynonyms(
      compareInput: {
        definitionOrdinal: $definitionOrdinal
        matchOn: $matchOn
        partOfSpeech: $partOfSpeech
        root: $root
      }
    ) {
      matches {
        definitions
        headword {
          displayForm
          slug
        }
        sharedAntonyms {
          displayForm
          slug
        }
        sharedSynonyms {
          displayForm
          slug
        }
      }
      root {
        displayForm
        slug
      }
    }
  }
`

const GET_THESAURUS_RELATED_WORDS = gql`
  query RelatedWords($targetWord: String) {
    relatedWords(targetWord: $targetWord) {
      results {
        pos
        shortDef
        slug {
          headword {
            displayForm
          }
          url
        }
        relatedWords {
          headword {
            displayForm
          }
          url
        }
      }
    }
  }
`

const GET_THESAURUS_RESULTS = gql`
  fragment ThesaurusResultsEntry on Entry {
    __typename
    audioPronunciations {
      mpeg
    }
  }

  fragment ThesaurusResultsThesaurusEntry on ThesaurusEntry {
    __typename
    headword {
      displayForm
      standardForm
    }
    partOfSpeechGroups {
      ordinal
      partOfSpeech
      shortDefinitions {
        antonyms {
          similarity
          targetWord
        }
        antonymsConnection {
          edges {
            node {
              targetWord
            }
            similarity
            targetSlug
          }
        }
        ordinal
        shortDef
        synonyms {
          similarity
          targetWord
        }
        synonymsConnection {
          edges {
            node {
              targetWord
            }
            similarity
            targetSlug
          }
        }
        thesRid
      }
    }
  }

  query ThesaurusResults($where: SlugWhere) {
    slugs(where: $where) {
      entries {
        ...ThesaurusResultsEntry
        ...ThesaurusResultsThesaurusEntry
      }
      url
    }
  }
`

const GET_BROWSE_BY_LETTER_RESULTS = gql`
  query SlugsByStartingLetter($input: QuerySlugsByStartingLetterInput) {
    slugsByStartingLetterResult: querySlugsByStartingLetter(input: $input) {
      slugHeadwordPairs {
        slug,
        displayForm
      }
      total
    }
  }
`;

export const createFetchDictionaryResults = async slug => {
  const response = await getCachedQueryResults({
    endpoint,
    query: GET_DICTIONARY_RESULTS,
    variables: {
      definitionOptions: { limit: 99, sort: { ordinal: 'ASC' } },
      partOfSpeechBlockOptions: { sort: { url: 'ASC' } },
      where: { url: slug }
    }
  })
  return response
}

export const fetchDictionaryResults = createAsyncThunk(
  'fetchDictionaryResults',
  async slug => {
    return await createFetchDictionaryResults(slug)
  }
)

export const fetchThesaurusCompareSynonyms = createAsyncThunk(
  'fetchThesaurusCompareSynonyms',
  async ({ definitionOrdinal, matchOn, partOfSpeech, root }) => {
    const results = await getCachedQueryResults({
      endpoint,
      query: GET_THESAURUS_COMPARE_SYNONYMS,
      variables: { definitionOrdinal, matchOn, partOfSpeech, root }
    })
    return results
  }
)

export const fetchThesaurusRelatedWords = createAsyncThunk(
  'fetchThesaurusRelatedWords',
  async ({ slug }) => {
    const results = await getCachedQueryResults({
      endpoint,
      query: GET_THESAURUS_RELATED_WORDS,
      variables: { targetWord: slug }
    })

    return results
  }
)

export const fetchThesaurusResults = createAsyncThunk(
  'fetchThesaurusResults',
  async ({ slug }) => {
    const results = await getCachedQueryResults({
      endpoint,
      query: GET_THESAURUS_RESULTS,
      variables: { where: { url: slug } }
    })

    return results
  }
)

export const fetchDictionaryBrowseByLetterResults = createAsyncThunk(
  'fetchDictionaryBrowseByLetterResults',
  async ({ letter, page = 1 }) => {
    const offset = (page - 1) * BROWSE_RESULTS_PER_PAGE;
    return await getCachedQueryResults({
      endpoint,
      query: GET_BROWSE_BY_LETTER_RESULTS,
      variables: {input:{
        by: letter,
        limit: BROWSE_RESULTS_PER_PAGE, 
        offset,
        source: 'dictionary'
      }}
    });
  }
);

export const fetchThesaurusBrowseByLetterResults = createAsyncThunk(
  'fetchThesaurusBrowseByLetterResults',
  async ({ letter, page = 1 }) => {
    const offset = (page - 1) * BROWSE_RESULTS_PER_PAGE;
    return await getCachedQueryResults({
      endpoint,
      query: GET_BROWSE_BY_LETTER_RESULTS,
      variables: {input:{
        by: letter,
        limit: BROWSE_RESULTS_PER_PAGE, 
        offset,
        source: 'thesaurus'
      }}
    });
  }
);
